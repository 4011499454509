import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router
} from 'react-router-dom';

import { useFetch } from './hooks/useFetch';
import { setStyles } from './redux/styles/actions';
import { setConfig } from './redux/config/actions';
import { excelTransform } from './controllers/excelController';

import './App.css';

import Layout from './components/Layout';
import Loader from './components/Loader';
import { Box, Content, Text } from './styles';
import VCards from './components/VCards';

function VCardApp() {
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [finalData, setFinalData] = useState(null);
  const dispatch = useDispatch();
  const { loading, data } = useFetch('./config.json');

  useEffect(() => {
    if (data) {
      console.log('data.config', data.config)
      dispatch(setConfig(data.config));
      const { API_SERVER, API_FOLDER, excel } = data.config;
      if (data.title) window.document.title = data.title;
      dispatch(setStyles(data.styles));
      async function fetchData() {
        const excelData = await excelTransform(API_SERVER, API_FOLDER, excel);
        if (excelData.error) {
          setError(true)
          setFinalData(excelData)
        } else {
          setFinalData(excelData)
        }
        setLoader(false)
      }
      fetchData();
    }
  }, [data])

  console.log('data', data)
  return (
    <Router>
      {loader ? (
        <Box display="flexbox" width="100vw" height="100vh" alignItems="center" justifyContent="center">
          <Loader color="#ccc" size={90} />
        </Box>
      ) : (
        <Layout>
          {error ? (
            <Content justifyContent="center">
              <Text color={data.styles.color}>{finalData.error}</Text>
            </Content>

          ) : (
            <>
              <VCards data={finalData} />
            </>
          )}
        </Layout>
      )
      }
    </Router>
  );
}

export default VCardApp;
