import React from 'react'
import styled from 'styled-components';
import { Box } from '../styles';

const xParser = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}
  const ImageLogo = styled.img`
    height: ${props => props.height};
    width: ${props => props.width};
  `

const HeaderImage = ({data}) => {

  return (
    <Box alignSelf={xParser[data.x]}>
      <ImageLogo src={data.src} height={data.height || 'auto'} width={data.width || 'auto'} />
    </Box>
  )
}

export default HeaderImage;