import defaultState from "./state";

export default function stylesReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_STYLES':
      return action.payload;
    default:
      return state;
  }
}
