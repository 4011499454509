import React from 'react'
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Card from './Card';
import { Text, Content, Box } from '../styles';
import styled from 'styled-components';

const LinkComponent = styled(Link)`
  font-family: 'Roboto', sans-serif;
  color: ${props => props.color};
  text-decoration: none;
  font-weight: 300;
`

const VCards = (props) => {
  const { data } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user = params.get('user');

  const colorText = useSelector(state => state.styles.color)

  const cardData = data.filter((card) => Number(card.id) === Number(user))[0];

  console.log('user', user)
  console.log('data', data)
  console.log('cardData', cardData)

  return (
    <>
      {cardData ? (
        <Content justifyContent="center">
          <Card cardData={cardData} />
        </Content>
      ) : (
        <Content justifyContent="flex-start" paddingTop="3rem !important">
          <ul>
            {data.map(card => (
              <li key={card.id}><LinkComponent to={`?user=${card.id}`} color={colorText}>{card.firstName} {card.lastName}</LinkComponent></li>
            ))}
          </ul>
        </Content>
      )}
    </>
  )
}

VCards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default VCards;