import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box } from '../styles';
import Header from './Header';


const Layout = ({ children }) => {
  const backgroundColor = useSelector(state => state.styles.background)



  return (
    <Box
      width="100vw"
      height="100vh"
      backgroundColor={backgroundColor}
      display="flex"
      flexDirection="column"
    >
      <Header />

      {children}

    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Layout