import styled from "styled-components";
import { Box } from ".";

export const Content = styled(Box)`
  height: 67vh;
  padding: 2.5vh;
  display: flex;
  align-items: center;
  overflow-y: auto;
  flex-direction: column;
  flex: 1 0 67vh;
`;
