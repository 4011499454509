import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import * as QR from '../controllers/qrCodeController';
import { Box, Text } from '../styles';
import styled from 'styled-components';

const CardDisplay = styled(Box)`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const CardContent = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`;

const Card = (props) => {
  const colorText = useSelector(state => state.styles.color)
  console.log('QR', QR)
  const { cardData } = props;
  const { API_SERVER, API_FOLDER } = useSelector(state => state.config);
  console.log(`vcard: ${API_SERVER}/${API_FOLDER}/${cardData.id}`)

  const pathFile = `${API_SERVER}/download/${API_FOLDER}/${cardData.id}`

  // console.log("def", QR.createVCard(testCard))



  function createQR() {
    return { __html: QR.createQr({ typeNumber: 10, data: pathFile, cellSize: 3, errorCorrectionLevel: 'H' }) }
    // return { __html: QR.createVCardQr(cardData, { typeNumber: 15, cellSize: 2, errorCorrectionLevel: 'M' }) }

  }

  return (
    <CardDisplay>
      <CardContent
        py="7vh"
        dangerouslySetInnerHTML={createQR()}
      />
      <CardContent>
        <Text as="h2" color={colorText} fontSize="2.9vh">{cardData.firstName} {cardData.lastName}</Text>
        <Text color={colorText} fontSize="2vh" fontWeight={300}>{cardData.title}</Text>
      </CardContent>
    </CardDisplay>
  )
}

Card.propTypes = {
  cardData: PropTypes.object.isRequired
}

export default Card;