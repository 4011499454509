import styled from 'styled-components'
import {
  flexbox,
  layout,
  color,
  typography,
  space,
  border,
  shadow,
  position,
  compose,
} from 'styled-system';

export const Box = styled.div`
  ${compose(
    layout,
    flexbox,
    space,
    color,
    typography,
    border,
    shadow,
    position
  )}
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  ${compose(
    space,
    color,
    typography,
    layout,
    position,
  )}
`;