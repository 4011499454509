import React from 'react'
import { useSelector } from 'react-redux';
import { Box } from '../styles';
import HeaderImage from './HeaderImages';

const Header = () => {
  const appStyles = useSelector(state => state.styles)

  return (
    <Box
      height="33vh"
      backgroundColor={appStyles.header_background}
      p="2.5vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex="1 0 33vh"
    >
      <HeaderImage data={appStyles.logo} />
      <HeaderImage data={appStyles.claim} />
    </Box>
  )
}

export default Header;