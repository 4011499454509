import axios from 'axios';


async function get(url) {
  try {
    const resp = await axios({ method: 'get', url });
    const { data } = resp;
    return data;
  } catch (err) {
    return null;
  }
}

export { get };