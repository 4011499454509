import { combineReducers, createStore } from 'redux';
import configReducer from './config/reducer';
import stylesReducer from './styles/reducer';

const AppPatterson = combineReducers({
  styles: stylesReducer,
  config: configReducer,
});

const initialState = {};

const appStore = createStore(
  AppPatterson,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default appStore;