import defaultState from "./state";

export default function configReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CONFIG':
      return action.payload;
    default:
      return state;
  }
}
